.searchInputs {
  display: flex;
}

.search input {
  width: 320px;
}

.searchIcon {
  height: 60px;
  width: 50px;
  background-color: white;
  display: grid;
  place-items: center;
}

input:focus {
  outline: none;
}

.searchIcon svg {
  font-size: 35px;
}

.dataResult {
  display: block;
  margin-top: 5px;
  width: 300px;
  height: 200px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow: hidden;
  overflow-y: auto;
  border-radius: 0 0 5px 5px;
  position: absolute;
}

.dataResult::-webkit-scrollbar {
	width: 10px;
  padding-right: 5px;
	background: #e2e2e2;
  position: relative;
}

.dataResult::-webkit-scrollbar-thumb {
	background: rgba(210, 1, 13,50);
	border-radius: 10px;
	border-right: 1px solid #b40009;
  padding: 1px;
  position: relative;
}

.dataResult .dataItem {
  width: 100%;
  height: auto;
  display: flex;
  margin: auto 0;
  padding: 0.5em 0;
  /* align-items: center; */
  color: black;
}

.dataItem p {
  margin-left: 10px;
  margin-bottom: 0;
}

.dataResult a {
  text-decoration: none;
}

.dataResult a:hover {
  background-color: lightgrey;
  border-left: 3px solid #d2010d;
  font-weight: 800;
}

#clearBtn {
  cursor: pointer;
}