/* div.Login{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
} */

div.Login {
    margin: 10em;
    padding: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Login h1 {
    font-family: "ColgateReady-Bold";
    color: #d2010d;
    font-size: 2.2em;
}

.Login p {
    text-align: center;
}

input.inputLogin {
    width: 400px;
}

.btn {
    background: #d2010d;
    color: #fff;
    font-weight: 800;
}

.btn:hover {
    color: #fff;
    transform: scale(1.05);
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.form .btnLogin {
    margin-top: 10px;
}

.llamar {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.llamar .separador{
    width: 1em;
}

.llamar p {
    width: max-content;
    margin: 5px;
}

.llamar img {
    width: 250px;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
input[type=number] { -moz-appearance:textfield; }

@media only screen and (max-width: 480px) {
    div.Login {
        width: 80%;
        margin: 10em auto;
    }

    div.Login h1 {
        font-size: 1.5em;
    }

    div.Login p {
        font-size: 0.8em;
    }

    input.inputLogin {
        width: 100%;
    }

    .llamar img {
        width: 200px;
    }
}