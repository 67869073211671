.landingPage {
    background: #e4e4e4;
    text-align: center;
}

.landingPage .imglandingPage{
    width: 100%;
}

.landingPage h2 {
    margin: 1em 0;
    font-size: 3em;
    color: #d2010d;
    font-family: "ColgateReady-Bold";
}

.landingPage p {
    font-size: 1.3em;
    color: #868483;
    font-family: "ColgateReady-Light";
}

.links {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4em;
}

.links a {
    text-decoration: none;
    font-family: "ColgateReady-Light";
    color: #8a8887;
}

.links a:hover {
    font-family: "ColgateReady-Bold";
    transform: scale(1.2);
    color: #d2010d;
    background: transparent;
}

.divSlider{
    margin-bottom: 4em;  
}

@media only screen and (max-width: 480px) {
    .landingPage {
        margin-top: 5em;
    }

    .links {
        display: none;
    }
    .landingPage h2 {
        display: none;
        /* margin: 1em 0;
        font-size: 1.5em;
        color: #d2010d;
        font-family: "ColgateReady-Bold"; */
    }
    
    .landingPage p {
        margin-top: 2em;
        font-size: 0.8em;
        color: #868483;
        font-family: "ColgateReady-Light";
    }
}