.imgButton{
    width: 5rem;
}

.url{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1em;

    font-family: "ColgateReady-Regular";
    text-decoration: none;
    color: #000000;
    font-weight: 500;
    font-style: italic;

    z-index: 100;
    position: relative;
    bottom: 150px;
    left: 60px;
}

button.url{
    border: 0px;
    background: transparent;
    padding: 0px;
}

.url:hover{
    background: transparent;
    transform: scale(1.03);
    filter: saturate(200%);
    color: #007aff;
}

.inactive {
    display: none;
}

@media only screen and (max-width: 770px) {
    .imgButton{
        width: 50px;
    }

    .url p{
        display: none;
    }

    .url{
        width: 80px;
        display: flex;
        /* flex-direction: column; */
        margin: 0.2em;
    
        z-index: 100;
        position: relative;
        bottom: 70px;
        left: -80px;
    }
}

@media only screen and (max-width: 480px) {
    .imgButton{
        width: 30px;
    }
    
    .url{
        width: 30px;
        display: flex;
        /* flex-direction: column; */
        margin: 0.2em;
    
        z-index: 100;
        position: relative;
        bottom: 40px;
        left: 10px;
    }

    .url p{
        display: none;
    }
    
}